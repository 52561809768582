import $ from "jquery";

export function loadPosts({
  page,
  postType,
  category,
  author,
  replace = false,
}) {
  // Posts elements
  const $grid = $(".js-posts-grid");
  const $featuredPost = $(".js-featured-post");
  const $loadMoreBtn = $(".js-posts-load-more");
  const $offset = 1;

  if (replace) {
    $grid.css("opacity", 0);
  } else {
    $grid.css("opacity", 0.5);
  }

  const data = { action: "theme_get_posts" };

  console.log(data);

  // Only pass in data if provided
  if (page) {
    data.page = page;
  } else {
    // First time we request posts, we want page 2 as page 1 is currently displayed
    data.page = 2;
  }
  if (postType) {
    data.postType = postType;
  } else {
    data.postType = "post";
  }

  if (category) {
    data.categories = category;
  }

  if (author) {
    data.author = author;
  }

  $.ajax({
    url: "/wp-admin/admin-ajax.php",
    method: "GET",
    data: data,
    success: function (res) {
      if (res.success) {
        // Make sure grid has animated out before swapping out HTML.
        setTimeout(function () {
          if (replace) {
            $grid.html(res.html);
          } else {
            $grid.append(res.html);
          }

          // if featured_html exists and isnt empty update html
          if (res.hasOwnProperty("featured_html") && res.featured_html !== "") {
            $featuredPost.html(res.featured_html);
          } else {
            $featuredPost.html();
          }

          if (res.load_more) {
            $grid.attr("data-page", res.next_page);
            $loadMoreBtn.show();
          } else {
            $loadMoreBtn.hide();
          }
          // Toggle load more button if there are more results.
          $grid.css("opacity", 1);
        }, 250);
      }
    },
  });
}
