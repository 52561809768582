import $ from "jquery";


document.addEventListener('contextmenu', event => event.preventDefault());

$(document).ready(function () {
  /**
   * Ensure gdpr checkbox returns 'false' if not checked and changes
   * to 'true' if checked.
   */
  $('form[name="gm_form"]').on("change", "input[type=checkbox]", function () {
    this.checked ? (this.value = "true") : (this.value = "false");
  });

  /**
   * If using Gator Smart Form Plugin add ReCaptcha before ConfirmedOptedIn
   */

  const smartFormSubmit = $(".smart-form .gf__submit");
  smartFormSubmit.addClass("btn btn--blue");

  const contactFormSubmit = $(".contact-form .gf__submit");
  contactFormSubmit.addClass("btn btn--red-lt");

  // $(".gf__control--FirstName--18,.gf__control--LastName--18").wrapAll(
  //   '<div class="my-name md:flex md:items-center"><div class="grid grid-cols-2 gap-4 w-full"></div></div>'
  // );
});
