import $ from "jquery";
import Swiper, { Autoplay, EffectFade, Pagination } from "swiper";

const headerCarousel = $(".js-header-carousel");
if (headerCarousel.length) {
  Swiper.use([Autoplay, EffectFade, Pagination]);
  const swiper = new Swiper(".js-header-carousel", {
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    effect: "fade",
    pagination: {
      el: ".js-header-pagination",
      clickable: true,
    },
  });
}
