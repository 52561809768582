import GLightbox from "glightbox";

const singleStory = GLightbox({
  selector: ".js-single-story",
  skin: "boss",
  openEffect: "fade",
  closeEffect: "fade",
  autoplayVideos: false,
});

const multiStory = GLightbox({
  selector: ".js-multi-story",
  skin: "boss",
  openEffect: "fade",
  closeEffect: "fade",
  autoplayVideos: false,
});

const ctaInsights = GLightbox({
  selector: ".js-insights",
  skin: "boss",
  openEffect: "fade",
  closeEffect: "fade",
  autoplayVideos: false,
});
