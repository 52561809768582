import $ from "jquery";
import { loadPosts } from "../utils/fetch-posts";

$(function () {
  // Retrieve the data object from local storage
  var storedCategory = localStorage.getItem("category");
  // Parse the string back into an object
  var getCategory = JSON.parse(storedCategory);

  // If getCategory found in local update filter

  if (getCategory) {
    let data = {
      page: 1,
      postType: $(".js-posts-load-more").attr("data-post-type") ?? "post",
      replace: true,
    };

    data.category = getCategory;
    loadPosts(data);
  }

  // If getCategory found in local add class to filter and check radio button
  $(".cat-filter__item").each(function () {
    var catAttr = $(this).attr("data-category");
    if (catAttr == getCategory) {
      $(this).prop("checked", true);
      $(this).addClass("cat-filter__item--active");
    }
  });

  // On posts load more click
  $(".js-posts-load-more").on("click", function () {
    const postType = $(".js-posts-load-more").attr("data-post-type") ?? "post";
    const author = $(this).attr("data-author");

    $(".cat-filter__item").each(function () {
      if ($(this).hasClass("cat-filter__item--active")) {
        const category = $(this).attr("data-category");
      }
    });

    let category;
    $(".cat-filter__item").each(function () {
      if ($(this).hasClass("cat-filter__item--active")) {
        category = $(this).attr("data-category");
        return false;
      }
    });

    console.log(category);

    loadPosts({
      page: $(".js-posts-grid").attr("data-page"),
      postType,
      category,
      author,
    });
  });

  // Update posts on category change
  $(".cat-filter__item").on("click", function () {
    $(".cat-filter__item").removeClass("cat-filter__item--active");
    $(this).addClass("cat-filter__item--active");

    let data = {
      page: 1,
      postType: $(".js-posts-load-more").attr("data-post-type") ?? "post",
      replace: true,
    };

    // Check category taxonomy from posts
    if ($(this).attr("data-category")) {
      data.category = $(this).attr("data-category");
    }

    // Remove category from local storage
    localStorage.removeItem("category");

    loadPosts(data);
  });

  // Remove category in local
  $(".btn-author").on("click", function () {
    localStorage.removeItem("category");
  });

  // On single post page store category in local
  $(".get-category").on("click", function () {
    localStorage.setItem(
      "category",
      JSON.stringify($(this).attr("data-category"))
    );
  });

  // Clear filter
  $(".js-clear").on("click", function () {
    $("input[name=category]").prop("checked", false);
    $(".cat-filter__item").removeClass("cat-filter__item--active");

    let data = {
      page: 1,
      replace: true,
    };

    // Remove category from local storage
    localStorage.removeItem("category");

    loadPosts(data);
  });
});