import $ from "jquery";

// Fixed Header Scroll

$(window).on("scroll", () => {
  var header = $(window).scrollTop();
  if (header > 50) {
    $("header").addClass("active");
  } else {
    $("header").removeClass("active");
  }
});

// Fixed Slider CTA

var fixedSliderCTA = $(".fixed-slider-cta").offset();

if ($(fixedSliderCTA).length > 0) {
  if ($(window).width() >= 768) {
    $(window).scroll(function () {
      if ($(window).scrollTop() > fixedSliderCTA.top - 80) {
        $(".fixed-slider-cta").css("position", "fixed").css("top", "80px");
      } else {
        $(".fixed-slider-cta").css("position", "static");
      }
    });
  }
}
