const navMenu = document.querySelector(".nav-menu");
const mobileBtnOpen = document.querySelector(".mobile-btn--open");
const mobileBtnClose = document.querySelector(".mobile-btn--close");

const navMobile = document.querySelector(".nav-mobile");
const navMobileSubmenu = document.querySelectorAll(".nav-mobile__submenu");
const navMobileLink = document.querySelectorAll(".nav-mobile__link");

mobileBtnOpen.addEventListener("click", () => {
  navMenu.classList.remove("translate-x-full");
});
mobileBtnClose.addEventListener("click", () => {
  navMenu.classList.add("translate-x-full");
});

navMobileSubmenu.forEach((el) => {
  const insert_close =
    '<li class="nav-mobile__link close"><a href="#" aria-label="Close"><svg xmlns="http://www.w3.org/2000/svg" class="mb-6 xl:mb-8" width="34.628" height="18.773"><g transform="rotate(90 17.314 17.314)"><path fill="#fff" d="M17.931 24.405c-2.957.831-5.458 3.206-7.165 5.263-1.5-13.479.47-29.1.493-29.267L8.168-.001c-.092.693-1.951 15.424-.613 28.949a156.374 156.374 0 0 0-3.8-3.658c-.659-.619-1.283-1.2-1.438-1.378L0 25.999c.232.261.716.716 1.622 1.567 1.891 1.773 5.827 5.469 6.738 7.062h2.707c.034-.06 3.469-6.028 7.706-7.217Z"/></g></svg></a></li>';
  el.insertAdjacentHTML("beforeend", insert_close);
});

navMobileLink.forEach((el) => {
  if (el.classList.contains("submenu")) {
    el.addEventListener("click", (evt) => {
      evt.preventDefault();
      el.nextSibling.classList.add("active");
    });
  }
});

const navMobilePrevious = document.querySelectorAll(".close");

navMobilePrevious.forEach((el) => {
  el.addEventListener("click", (evt) => {
    evt.preventDefault();

    el.parentElement.classList.remove("active");
  });
});
