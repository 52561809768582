const accordion = document.querySelectorAll(".accordion__item");

accordion.forEach((item) => {
  let title = item.querySelector(".accordion__title");

  title.onclick = function () {
    item.classList.toggle("active");

    let content = item.querySelector(".accordion__content");

    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };
});
