import $ from "jquery";
import Swiper, { Navigation } from "swiper";

const cardCarousel = $(".js-cards-carousel");
if (cardCarousel.length) {
  Swiper.use([Navigation]);
  const swiper = new Swiper(".js-cards-carousel", {
    loop: true,
    spaceBetween: 16,
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 5,
      },
    },
  });
  $(".js-cards-prev").on("click", function () {
    swiper.slidePrev();
  });
  $(".js-cards-next").on("click", function () {
    swiper.slideNext();
  });
}
