import $ from "jquery";
import Swiper, { Navigation } from "swiper";

const defaultCarousel = $(".js-default-carousel");
if (defaultCarousel.length) {
  Swiper.use([Navigation]);
  const swiper = new Swiper(".js-default-carousel", {
    watchOverflow: true,
    loop: true,
    spaceBetween: 16,
    on: {
      afterInit: function () {
        if (this.slides.length == 3) {
          $(".js-default-prev").hide();
          $(".js-default-next").hide();
        }
      },
    },
  });
  $(".js-default-prev").on("click", function () {
    swiper.slidePrev();
  });
  $(".js-default-next").on("click", function () {
    swiper.slideNext();
  });
}
